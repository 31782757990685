import { useState, useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'next-i18next';
import LogoNew from '@assets/img/home/logo-new.svg';
import { useRouter } from 'next/router';
import { useModalAction } from '@components/ui/modal/modal.context';
import dynamic from 'next/dynamic';
import cn from 'classnames';
import { ROUTES } from '@lib/routes';
import { fetchNotificationReadIds } from '@components/notifications/notificationSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNotificationReadIdsQuery } from "@framework/notifications/notifications.query";
import { RootState } from 'src/root-redux/store';
import Image from '@components/ui/image';
import { initData as totalCountInit } from "@components/layouts/totalCountSlice";
import { initData as favoritesCountInit } from "@components/my-favorites/myFavoritesSlice";
import { initData as cartItemsInit } from "@components/cart/cartSlice";
import { CircleFlagVi } from '@components/icons/new/circle-flag-vi';
import { CircleFlagCs } from '@components/icons/new/circle-flag-cs';
import { Check } from '@components/icons';
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock';
import Link from '@components/ui/link';
import TransportTruckIcon from '@components/icons/new/transport-truck-icon';
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import { MinicartWarningIcon } from '@components/icons/new/minicart-warning-icon';
import NotificationIcon from '@components/icons/sidebar/notification-icon';
import { toast } from 'react-toastify';
import { CaSearchIcon } from '@components/icons/ca-search-icon';

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

const AuthorizedMenu = dynamic(() => import('@components/layouts/menu/authorized-menu'));
const NotificationsMenu = dynamic(() => import('@components/layouts/menu/notifications-menu'));
const IndustriesMenu = dynamic(() => import('@components/layouts/menu/industries-menu'));
const CategoriesMenu = dynamic(() => import('./menu/categories-menu'));
const SearchIcon = dynamic(() => import('@components/icons/search-icon'));
const HeaderCloseMenuCategoriesIcon = dynamic(() => import('@components/icons/new/header-close-menu-categories-icon'));
const NavigatorBarMobile = dynamic(() => import('./menu/navigator-bar-mobile'));
const NavigatorBar = dynamic(() => import('./menu/navigator-bar'));
const AutoSuggestSearch = dynamic(() => import('@components/home-search/auto-suggest-search'));
const KeywordBar = dynamic(() => import('./menu/keyword-bar'));

type DivElementRef = React.MutableRefObject<HTMLDivElement>;

const Header = () => {
  const [active, setActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [switchLanguageToggle, setSwitchLanguageToggle] = useState(false);
  const currentLangue = (typeof window !== 'undefined' && window.location.href.includes("/cz") ? "cz" : "vi");
  const ref = useRef() as DivElementRef;
  const menuShopRef = useRef() as DivElementRef;
  const wrapperRef = useRef<any>();
  const menuRef = useRef<any>();
  const {
    i33Settings: { isMobileOnly, isTablet, isIOS },
    authenticationSettings: { isAuthorize }
  } = useSelector((state: RootState) => state);
  const nowDate = dayjs(new Date());
  const showNotice = false
  const unreadNotifications = useSelector(
    (state: RootState) => state.totalCounts.notifications
  );
  const handleToggle = useCallback((e: any) => {
    e.preventDefault();
    setActive((active) => !active);
  }, []);
  const router = useRouter();
  const { searchText } = router.query;
  const [activeMenuShop, setActiveMenuShop] = useState(false);
  const handleToggleMenuShop = useCallback((e: any) => {
    e.preventDefault();
    setActiveMenuShop((activeMenuShop) => !activeMenuShop);
  }, []);
  const { t } = useTranslation('common');

  //header cart
  const { action, index, productDetailPopupSlug } = router.query;
  const { openModal } = useModalAction();

  if (!loading) {
    switch (action) {
      case 'login':
        if(!isAuthorize) openModal('LOGIN_VIEW', { defaultIndex: index || 0 });
        break;
      case 'reset-password':
        if(!isAuthorize) openModal('RESET_PASSWORD_VIEW');
        break;
      case 'productDetail':
        openModal('PRODUCT_DETAILS', { slug: productDetailPopupSlug } );
        break;
    }
  }

  const [textquery, setTextQuery] = useState<string | any>('');
  const [valuecategoryId, setValueCategoryId] = useState<string | any>('');

  useEffect(() => {
    const { searchText, parentId } = router.query;
    setTextQuery(searchText || "");
    if (parentId) setValueCategoryId(parentId as string);
  }, [router.query]);

  useEffect(() => {
    if(!loading) setLoading(true);
  }, [loading])

  const categoryIdCallback = (value: any) => {
    setValueCategoryId(value);
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, [wrapperRef]);

  function handleClickOutside(event: { target: any; }) {
    // @ts-ignore
    if ([wrapperRef, menuRef].every(item => item.current && !item.current.contains(event.target))) {
      setSwitchLanguageToggle(false);
    }
  };


  useEffect(() => {
    if(isIOS) return;

    if (ref.current) {
      if (active) {
        disableBodyScroll(ref.current);
      } else {
        enableBodyScroll(ref.current);
      }
    }

    return () => {
      clearAllBodyScrollLocks();
    };
  }, [active]);

  useEffect(() => {
    if(isIOS) return;

    if (menuShopRef.current) {
      if (activeMenuShop) {
        disableBodyScroll(menuShopRef.current);
      } else {
        enableBodyScroll(menuShopRef.current);
      }
    }

    return () => {
      clearAllBodyScrollLocks();
    };
  }, [activeMenuShop]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    let { sortPrice, type }: any = router.query;

    if(router.pathname != "/search") {
      sortPrice = null;
      type = null;
    }

    router.push(`/search?page=1&searchText=${textquery}&parentId=${valuecategoryId}&sortPrice=${sortPrice || ""}&type=${type || ""}`);
  };

  const {
    myFavorites: {
      shopLoading,
      productLoading,
      promotionLoading,
    },
    totalCounts: { loading: totalCountsLoading },
    cart: { cartItemsLoading }
  } = useSelector((state: RootState) => state);

  const {
    data: notificationReadIds,
    isLoading: notificationReadIdsLoading
  } = useNotificationReadIdsQuery({ enabled: isAuthorize })

  if (isAuthorize && !shopLoading) dispatch(favoritesCountInit("shop"));
  if (isAuthorize && !productLoading) dispatch(favoritesCountInit("product"));
  if (isAuthorize && !promotionLoading) dispatch(favoritesCountInit("promotion"));
  if (isAuthorize && !totalCountsLoading) dispatch(totalCountInit());
  if (!notificationReadIdsLoading) dispatch(fetchNotificationReadIds(notificationReadIds));

  useEffect(() => {
    if (isAuthorize && !cartItemsLoading) dispatch(cartItemsInit());
  }, [isAuthorize, cartItemsLoading])

  function checkLogin(targetUrl: any) {
    if (isAuthorize) {
      router.push(targetUrl);
    } else {
      toast.error(t('authentication-required'));
      router.push(ROUTES.MOBILE_LOGIN);
    }
  }

  return (
    <>
      <div id="header" className={cn("header-pc header shadow-sm be-viet-pro top-0 z-[100]", isMobileOnly && 'sticky')}>
        <div id="header-top-fixed" className={cn(isMobileOnly ? 'bg-white' : 'header-top')}>
          <div className="container mx-auto">
            <div className={cn("grid grid-cols-1 lg:grid-cols-3 gap-2 lg:gap-6", isMobileOnly ? "flex-wrap justify-between" : "")}>
              <div className={cn('flex w-full xl:w-fit col-span-2')}>
                <div className={cn("logo flex", isMobileOnly ? "justify-between w-full " : "justify-center lg:justify-start")}>
                  {isMobileOnly &&
                  <div className="languages sm:pt-[5px] flex justify-between items-center">
                    <div ref={wrapperRef} onClick={() => {setSwitchLanguageToggle(!switchLanguageToggle)}}>

                      {currentLangue == "cz" ? <CircleFlagCs /> : <CircleFlagVi />}
                    </div>
                    {switchLanguageToggle && (<div ref={menuRef} className='absolute left-[15px] top-[112px] z-10 bg-light shadow-300 rounded'>
                      <Link href={ROUTES.HOME} locale="vi" className='w-[80px] border-b p-1 flex'>
                        <CircleFlagVi />
                        { currentLangue != "cz"  && <Check />}
                      </Link>
                      <Link href={ROUTES.HOME} locale="cz" className='w-[80px] border-b p-1 flex'>
                        <CircleFlagCs />
                        { currentLangue == "cz"  && <Check />}
                      </Link>
                    </div>)}
                  </div>}
                  <span className='py-2 sm:py-0 min-w-[85px] sm:min-w-[120px]'>
                    <Link href="/" className={isMobileOnly ? 'flex flex-end' : ''}>
                      <Image
                        priority
                        src={LogoNew}
                        alt="main-banner"
                        width={isMobileOnly ? "85" : "120"}
                        height={isMobileOnly ? "30" : "40"}
                      />
                    </Link>
                  </span>

                  {isMobileOnly && <a className={"btn-notification-header cursor-pointer hover:bg-accent/[0.2] rounded-[8px] p-2"} onClick={() => { checkLogin(ROUTES.NOTIFICATIONS)}}>
                    <NotificationIcon width="30" height="30" fill={unreadNotifications > 0 ? "#6AA543" : "#5C6370"}/>
                  </a>}
                </div>

                {!isMobileOnly && <div className="header-search-form flex-2" >
                  <form
                    id="search-front"
                    className="search-form"
                    onSubmit={handleSubmit}
                  >
                    <div className="col-header-seach min-h-[40px]">
                      <AutoSuggestSearch inputSearch={textquery}
                        setInputSearch={setTextQuery}/>
                      <button
                        className="btn btn-default btn-search text-xs py-3 text-accent font-semibold bg-gray-2300 relative"
                        type="submit"
                        aria-label="search"
                      >
                        <div className="w-[1px] h-[16px] bg-gray-2200 absolute left-0 top-[12px]"></div>
                        {t("text-search-page")}
                      </button>
                    </div>
                    {!isMobileOnly && <KeywordBar />}
                  </form>
                </div>}
              </div>

              {!isMobileOnly && <div className={cn('flex items-center justify-end lg:justify-between w-full xl:w-fit max-h-[48px] lg:max-h-fit')}>
                <div className="languages relative">
                  <div ref={wrapperRef} className="border border-accent rounded-full" onClick={() => {setSwitchLanguageToggle(!switchLanguageToggle)}}>
                    {currentLangue == "cz" ? <CircleFlagCs /> : <CircleFlagVi />}
                  </div>
                  {switchLanguageToggle && (<div ref={menuRef} className='absolute right-0 top-[45px] z-10 bg-light shadow-300 rounded'>
                    <Link href={ROUTES.HOME} locale="vi" className='w-[64px] border-b p-1 flex items-center justify-between'>
                      <CircleFlagVi />
                      { currentLangue != "cz"  && <Check />}
                    </Link>
                    <Link href={ROUTES.HOME} locale="cz" className='w-[64px] border-b p-1 flex items-center justify-between'>
                      <CircleFlagCs />
                      { currentLangue == "cz"  && <Check />}
                    </Link>
                  </div>)}
                </div>

                <span className="col-header-bar-languages-login mx-2 sm:mx-6 lg:mx-0">
                  <AuthorizedMenu />
                </span>
                <NotificationsMenu isAuthorize={isAuthorize} />
              </div>}
            </div>
          </div>
          
          {isMobileOnly && <div className={cn('relative border-t border-gray-2300 w-full px-3 py-2 bg-light')}>
            <input
              type="text"
              className="form-control bg-gray-2300 text-gray-1500 px-4 py-2.5 w-full leading-6 rounded pl-12 text-sm rounded-[100px]"
              autoComplete="off"
              placeholder={t('new-text-placehoder-search-header-mobile')}
              value={searchText}
              onClick={(e: any) => {
                e.target.blur();
                openModal('SEARCH_POPUP');
              }}
            />
            <span className='absolute left-8 top-[18px]'><CaSearchIcon /></span>
          </div>}
        </div>
        {showNotice && <div className="bg-light border-t border-gray-2300 py-1">
          <div className="container mx-auto py-1 flex items-center text-xs sm:text-sm text-red-20">
            <span className="mr-1"><MinicartWarningIcon color="#FF1D30" /></span>
            <span>{t("system-notice")}</span>
          </div>
        </div>}
        {!isMobileOnly && <NavigatorBar activeMenuShop={activeMenuShop} handleToggleMenuShop={handleToggleMenuShop} handleToggle={handleToggle} />}
      </div>
      {isMobileOnly && <KeywordBar />}
      {isMobileOnly && <NavigatorBarMobile activeMenuShop={activeMenuShop} handleToggleMenuShop={handleToggleMenuShop} handleToggle={handleToggle} />}
      <div
        ref={ref}
        className={
          active
            ? `aside-extra-menu--categories open-aside-extra-menu ${isMobileOnly ? "w-full" : "w-[390px]"}`
            : 'aside-extra-menu--categories'
        }
      >
        <div className="list-menu--categories">
          <div className="title">
            {t('header-menu-categories')}
            <div className="cursor-pointer" onClick={handleToggle}>
              <HeaderCloseMenuCategoriesIcon />
            </div>
          </div>
          <CategoriesMenu setActive={setActive} active={active} />
        </div>
      </div>
      <div
        onClick={handleToggle}
        className={
          active
            ? 'la-overlay-global la-overlay-global--active'
            : 'la-overlay-global'
        }
      ></div>
      <div
        ref={menuShopRef}
        className={
          activeMenuShop
            ? `aside-extra-menu--categories open-aside-extra-menu ${isMobileOnly && "w-full"}`
            : 'aside-extra-menu--categories'
        }
      >
        <div className="list-menu--categories">
          <div className="title">
            {t('header-shop-categories')}
            <div className="cursor-pointer" onClick={handleToggleMenuShop}>
              <HeaderCloseMenuCategoriesIcon width="24" height="24" />
            </div>
          </div>
          <IndustriesMenu activeMenuShop={activeMenuShop} />
        </div>
      </div>
      <div
        onClick={handleToggleMenuShop}
        className={
          activeMenuShop
            ? 'la-overlay-global la-overlay-global--active'
            : 'la-overlay-global'
        }
      ></div>
    </>
  );
};

export default Header;
